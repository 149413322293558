@tailwind components;

@layer components{
    .logo-container {
        @apply flex items-center justify-center md:w-1/2 w-3/4;
    }
    .login-logo {
        @apply md:w-3/4 w-full h-full;
    }
    .danger-text{
        @apply mb-3 text-red-400 transition duration-150 ease-in-out hover:text-red-600 focus:text-red-600 active:text-red-700 text-center bg-red-100;
    }
    .top-text{
        @apply mb-3 text-red-400 text-center;
    }
    .top-link{
        @apply mb-4 text-red-400 underline transition duration-150 ease-in-out hover:text-red-600 focus:text-red-600 active:text-red-700 text-center;
    }
    .form-head-text{
        @apply mb-4 text-2xl font-semibold text-center;
    }
    .login-section{
        @apply h-screen p-6 md:p-2 flex flex-col items-center justify-center bg-gray-100 w-full
    }
    .form-container {
        @apply bg-white p-6 rounded-lg border border-gray-600 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 shadow-lg hover:shadow-xl transition duration-300;
    }
    .role-dropdown-container {
        @apply block mb-4 rounded-lg border  w-full flex;
    }
}