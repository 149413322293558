@tailwind components;

@layer components{
  .flex-center{
    @apply flex items-center;
  }
  .class-result{
    @apply flex flex-col items-center justify-center w-full mt-3;
  }

  .class-select-dropdown{
    @apply block mb-4 rounded-lg border  w-auto flex;
  }

  .school-table{
    @apply w-full mb-6 flex-center flex-col;
  }
}