@tailwind components;

@layer components {

    .navbar-logo {
        @apply flex items-center justify-start md:justify-center w-3/5 md:w-1/5 md:border-r md:border-gray-500 py-2 px-3 h-full;
    }

    .navbar-brand {
        @apply h-12 md:h-16 w-auto;
    }

    .sidebar-list {
        @apply fixed space-y-0 md:block w-full md:w-1/5;
    }

    .sidebar-item {
        @apply block py-2 px-4 hover:bg-gray-400 transition-colors border-b border-gray-500 text-center;
    }

    .sidebar-item.active {
        @apply bg-gray-400;
    }

    .welcome-text {
        @apply text-black text-lg px-2 hidden md:block;
    }

    .logout-button {
        @apply bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded text-center ml-auto;
    }

    .sidebar {
        @apply sticky w-2/5 md:w-1/5 bg-gray-300 text-black py-0 px-0 border-r border-b border-gray-500 md:block z-10 md:z-0 transition-all duration-300 ease-in-out;
    }

    .navbar {
        @apply sticky top-0 w-full bg-gray-300 pl-1 pr-4 py-0 border-b border-gray-500 flex items-center z-40;
    }

    .content {
        @apply w-full flex h-full flex-grow relative;
    }

    .main-content {
        @apply w-full md:w-4/5 p-4 ml-0 bg-gray-100 overflow-y-auto flex flex-col items-center relative z-10;
    }

    .main-section {
        @apply bg-gray-100 flex flex-col w-full min-h-screen;
    }

    @media (max-width: 767px) {
        .sidebar.open {
            @apply translate-x-0 z-20 absolute inset-y-0 left-0;
        }

        .sidebar.close {
            @apply -translate-x-full z-20 absolute inset-y-0 left-0;
        }
    }
}