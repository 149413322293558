@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    @apply bg-gray-300;
}

.heading {
    @apply text-sm-bold sm:text-base-bold md:text-lg-bold lg:text-xl-bold self-start mb-2 md:mb-3 leading-6;
}

.text {
    @apply text-xs sm:text-sm md:text-base lg:text-lg self-start mb-2;
} 

.text-bold {
    @apply text-xs-bold sm:text-sm-bold md:text-base-bold lg:text-lg-bold self-start mr-2;
}