@tailwind components;

@layer components {
    .flex-center{
        @apply flex items-center;
    }

    /* Result Section */
    
    .result-section {
        @apply flex-center flex-col md:w-3/4 w-full;
    }
    
    .result-summary {
        @apply w-full flex-center flex-wrap justify-between bg-white p-6 rounded-lg p-6 mb-6;
    }

    .summary-item {
        @apply w-full md:w-1/2 flex mb-2;
    }

    .summary-key {
        @apply text-base md:text-lg font-semibold mr-2;
    }

    .summary-value {
        @apply text-base md:text-lg font-normal;
    }

    .topics-data {
        @apply flex-center flex-col w-full mb-6;
    }

    .result-chart {
        @apply w-full bg-white p-3 rounded-lg flex-center flex-col justify-center mb-3;
    }

    .table-layout {
        @apply w-full mb-3;
    }

    .result-sub-section{
        @apply w-full mb-6 flex-center flex-col;
    }

    .chart-heading{
        @apply text-left text-base sm:text-lg md:text-xl text-gray-600 font-bold self-start;
    }

    .tabs-item {
        @apply inline-block px-4 py-3 rounded-lg bg-gray-300 hover:bg-gray-950 text-black hover:text-white cursor-pointer mb-2;
    }

    .tabs-item.active {
        @apply !bg-gray-950 !text-white;
    }

    .tabs-container {
        @apply hidden md:flex flex-wrap text-center text-gray-500 dark:text-gray-400 mb-3;
    }

    .dropdown-container {
        @apply block md:hidden mb-3;
    }

    .legend-container {
        @apply flex flex-wrap mb-3 bg-white p-2 rounded-lg text-[12px] lg:text-[13px] self-start font-[Arial];
    }

    /* Modal */

    .modal-container{
        @apply relative bg-gray-200 p-4 overflow-y-auto flex flex-col items-center justify-center w-5/6 sm:w-2/3 md:w-1/2 h-[80vh] rounded shadow-2xl z-50;
    }
    
    .modal-background{
        @apply fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50;
    }

    .modal-close{
        @apply absolute top-4 right-10 p-2 text-gray-600 hover:text-gray-800 z-10;
    }

    /* Help Section */

    .help-section{
        @apply bg-white p-6 rounded-lg border border-gray-600 w-full sm:w-2/3 md:w-1/2 mt-10;
    }

    .help-heading{
        @apply text-2xl font-bold mb-1;
    }

    .help-subheading{
        @apply mb-4 text-gray-600;
    }

    .help-success{
        @apply text-green-600 font-semibold;
    }

    .help-error{
        @apply text-red-600 font-semibold;
    }
}
  