@tailwind components;

@layer components {
    .input-field{
        @apply border border-gray-600 rounded-md py-1 px-2 sm:py-2 w-full bg-gray-100 disabled:bg-gray-200;
    }

    .submit-btn{
        @layer text;
        @apply bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-600;
    }

    .input-section {
        @apply flex flex-col items-start;
    }

    .help-text {
        @layer text;
        @apply text-gray-500 cursor-pointer hover:text-blue-500;
    }

    .form-footer {
        @apply flex justify-between items-center;
    }
}